<!-- 团长分销页面 -->
<template>
  <div class="colonelDistribution">
    <div class="head-area clr">
      <div class="fl clr left-search">
        <div class="fl search-item">
          <div class="fl head-label-area">用户ID</div>
          <Input class="fl" v-model="id" placeholder="请输入用户ID" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">用户昵称</div>
          <Input class="fl" v-model="nickName" placeholder="请输入用户昵称" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">推荐人昵称</div>
          <Input
            class="fl"
            v-model="parentName"
            placeholder="请输入用户昵称"
            clearable
            style="width:200px"
          ></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">加入时间</div>
          <DatePicker
            v-model="registerTime"
            format="yyyy-MM-dd"
            type="daterange"
            placement="bottom-end"
            placeholder="请选择时间"
            style="width: 200px"
          ></DatePicker>
        </div>
        <!-- <div class="fl search-item">
          <div class="fl head-label-area">分销状态</div>
          <Select v-model="status" clearable style="width:200px">
            <Option value="1">正常</Option>
            <Option value="2">冻结</Option>
          </Select>
        </div> -->
      </div>
      <div class="fr right-search-btn">
        <Button type="primary" style="margin:0 0 10px;" icon="ios-search" @click="search">查询</Button>
        <Button @click="reset" icon="ios-refresh">重置</Button>
      </div>
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" :height="tableHeight">
        <template #avatar="{ row }">
          <img style="width:36px;height:36px;margin-top:5px" :src="row.avatar" alt />
        </template>
        <template #action="{ row }">
          <a @click="checkDetial(row)">查看</a>
          <!-- <a style="margin-left:5px" @click="checkDetial(row)">冻结</a> -->
          <!-- <a style="color:red;margin-left:5px" @click="delRow(row)">删除</a> -->
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <div class="detial-page-area" v-if="modelShow">
      <detialPage
        v-if="modelShow"
        :currentData="currentData"
        @close="modelShow = false"
        @editSuccess="findPageDataList()"
      />
    </div>
  </div>
</template>

<script>
import { getColonelBrokerageList } from '@/api/colonelDistribution.js'
import detialPage from './components/detialPage.vue'

export default {
  components: {
    detialPage
  },
  data() {
    return {
      modelShow: false,
      id: '',
      nickName: '',
      parentName: '',
      registerTime: [],
      status:'',
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      searchText: '',
      tableHeight: window.innerHeight - 310,
      total: 0,
      current: 1,
      size: 20,
      columns: [
         {
          title: 'id',
          key: 'userId',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '头像',
          key: 'avatar',
          slot: 'avatar',
          width: 80
        },
        {
          title: '用户昵称',
          key: 'nickname',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '手机号',
          key: 'mobile',
        },
        {
          title: '加入时间',
          key: 'registerTime',
        },
        {
          title: '推荐人昵称',
          key: 'parentName',
          ellipsis: true,
          tooltip: true,
        },
         {
          title: '团队人数',
          key: 'teamCount',
        },
        {
          title: '累计收益',
          key: 'totalEarnings',
        },
        {
          title: '操作',
          slot: 'action',
          width: 120
        }
      ],
      dataList: [
      ],
      currentData: {}
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    reset() {
      this.id = ''
      this.nickName = ''
      this.parentName = ''
      this.registerTime = []
      this.status = ''
      this.current = 1
      this.findPageDataList()
    },
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      let params = { pageIndex: this.current, size: this.size }
      params.id = this.id
      params.nickName = this.nickName
      params.parentName = this.parentName
      // params.status = this.status
      if (this.registerTime[0] || this.registerTime[1]) {
        params.startDate = this.registerTime[0]
        params.endDate = this.registerTime[1]
      }
      getColonelBrokerageList(params).then(res => {
        console.log(res)
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    checkDetial(row) {
      this.modelShow = true
      this.currentData = JSON.parse(JSON.stringify(row))
    },
    delRow(row) {
      this.$Modal.confirm({
        title: "确认删除？",
        onOk: () => {
          // deleteMember({ id: row.id }).then(res => {
          //   if (res.success) {
          console.log(row)
          this.$Message.success('删除成功！')
          this.findPageDataList()
          //   }
          // })
        },
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
  }
}

</script>
<style lang='scss' scoped>
.colonelDistribution {
  .left-search {
    width: 900px;
    .search-item {
      margin-bottom: 10px;
    }
  }
  .right-search-btn {
    width: 100px;
    border-left: 1px solid #e5e6eb;
    padding-left: 20px;
  }
  .head-label-area {
    line-height: 36px;
    width: 90px;
    padding-right: 15px;
    text-align: right;
  }
  .detial-page-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // padding: 20px;
    background: #f5f7f9;
    z-index: 100;
    overflow: auto;
  }
}
</style>