<template>
  <div class="detial-box">
    <div class="head-area clr">
      <div class="avatar fl">
        <img :src="currentData.avatar" alt />
      </div>
      <div class="header-tip fl">
        <div class="header-tip-desc clr">
          <div class="header-tip-desc-sp clr">
            <div class="tip-label fl">用户昵称：</div>
            <div class="tip-value fl">{{currentData.nickname}}</div>
          </div>
          <div class="header-tip-desc-sp clr">
            <div class="tip-label fl">手机号：</div>
            <div class="tip-value fl">{{currentData.mobile}}</div>
          </div>
          <div class="header-tip-desc-sp clr">
            <div class="tip-label fl">推荐人：</div>
            <div class="tip-value fl">{{currentData.parentName}}</div>
          </div>
        </div>
      </div>
      <div class="header-right-area fl">
        <div class="header-right-item fl">
          <div class="tit-area">累计收益</div>
          <div class="num-area">{{baseInfo.totalEarnings}}</div>
          <div class="zr-area">
            昨日
            <span>{{baseInfo.yerEarnings}}</span>
          </div>
        </div>
        <!-- <div class="header-right-item fl">
          <div class="tit-area">累计提现</div>
          <div class="num-area">{{baseInfo.totalBalance}}</div>
          <div class="zr-area">
            昨日
            <span>{{baseInfo.yerBalance}}</span>
          </div>
        </div>-->
        <div class="header-right-item fl">
          <div class="tit-area">余额</div>
          <div class="num-area">{{baseInfo.totalBalance}}</div>
          <div class="zr-area">
            昨日
            <span>{{baseInfo.yerBalance}}</span>
          </div>
        </div>
      </div>
      <div class="edit-btn">
        <Button style="margin-right:10px" @click="$emit('close')">返回</Button>
      </div>
    </div>
    <div class="content-area">
      <div class="table-content">
        <div class="date-change">
          <DatePicker
            @on-change="dateChange"
            v-model="dateVal1"
            type="month"
            placeholder="选择月份"
            style="width: 200px"
          ></DatePicker>
        </div>
        <Tabs v-model="tabVal1" :animated="false">
          <TabPane label="团长推广" name="1"></TabPane>
          <TabPane label="商家推广" name="2"></TabPane>
          <TabPane label="用户推广" name="3"></TabPane>
        </Tabs>
        <div class="table-area">
          <Table size="small" :columns="columns" :data="dataList"></Table>
        </div>
        <div class="page-area" v-if="dataList.length">
          <Page
            :total="total"
            :current="current"
            show-total
            :page-size="size"
            @on-change="pageChage"
          ></Page>
        </div>
      </div>
      <div class="table-content">
        <div class="date-change">
          <DatePicker
            @on-change="dateChange2"
            v-model="dateVal2"
            type="month"
            placeholder="选择月份"
            style="width: 200px"
          ></DatePicker>
        </div>
        <Tabs v-model="tabVal2" :animated="false">
          <TabPane label="收益明细" name="1"></TabPane>
          <!-- <TabPane label="提现记录" name="2"></TabPane> -->
        </Tabs>
        <div class="table-area">
          <Table size="small" :columns="columns2" :data="dataList2">
            <!-- <template #avatar="{ row }">
              <img style="width:36px;height:36px;margin-top:5px" :src="row.avatar" alt />
            </template>-->
          </Table>
        </div>
        <div class="page-area" v-if="dataList2.length">
          <Page
            :total="total2"
            :current="current2"
            show-total
            :page-size="size"
            @on-change="pageChage2"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getColonelBaseInfo, getColonelSpread, getStoreSpread, getUserSpread, getIntegralList } from '@/api/colonelDistribution.js'

export default {
  components: {
  },
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      baseInfo: {},
      tabVal1: '1',
      tabVal2: '1',
      dateVal1: '',
      dateVal2: '',
      total: 0,
      current: 1,
      size: 5,
      total2: 0,
      current2: 1,
      columns: [
        {
          title: '下级成员',
          key: 'name',
        },
        {
          title: '加入时间',
          key: 'createTime',
          width: 160,
        },
        {
          title: '手机号',
          key: 'mobile',
        },
        {
          title: '活动收益',
          key: 'colonelEarnings',
        },
        {
          title: '用户消费',
          key: 'userEarnings',
        },
        {
          title: '商家流水',
          key: 'storeEarnings',
        },
        {
          title: '分享注册',
          key: 'userShareEarnings',
        },
        {
          title: '收益圈豆',
          key: 'integralEarnings',
        },
      ],
      dataList: [],
      columns2: [
        {
          title: '流水号',
          key: 'id',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '来源',
          key: 'typeName',
        },
        {
          title: '方式',
          key: 'operationTypeName',
        },
        {
          title: '数量',
          key: 'changeAmount',
        },
        {
          title: '入账时间',
          key: 'createTime',
        },
        {
          title: '当前余额',
          key: 'changeAmount',
        }
      ],
      dataList2: [],
    }
  },
  watch: {
    tabVal1(n) {
      this.current = 1
      if (n == 1) {
        this.columns = [
          {
            title: '下级成员',
            key: 'name',
          },
          {
            title: '加入时间',
            key: 'createTime',
            width: 160,
          },
          {
            title: '手机号',
            key: 'mobile',
          },
          {
            title: '活动收益',
            key: 'colonelEarnings',
          },
          {
            title: '用户消费',
            key: 'userEarnings',
          },
          {
            title: '商家流水',
            key: 'storeEarnings',
          },
          {
            title: '分享注册',
            key: 'userShareEarnings',
          },
          {
            title: '圈豆收益',
            key: 'integralEarnings',
          },
        ]
      }
      else if (n == 2) {
        this.columns = [
          {
            title: '下级商家',
            key: 'name',
          },
          {
            title: '加入时间',
            key: 'createTime',
            width: 160,
          },
          {
            title: '手机号',
            key: 'mobile',
          },
          {
            title: '商家流水',
            key: 'storeEarnings',
          },
          {
            title: '圈豆收益',
            key: 'integralEarnings',
          }
        ]
      }
      else if (n == 3) {
        this.columns = [
          {
            title: '下级用户',
            key: 'name',
          },
          {
            title: '加入时间',
            key: 'createTime',
            width: 160,
          },
          {
            title: '手机号',
            key: 'mobile',
          },
          {
            title: '用户消费',
            key: 'userEarnings',
          },
          {
            title: '圈豆收益',
            key: 'integralEarnings',
          }
        ]
      }
      this.findPageDataList()
    },
  },
  mounted() {
    this.getColonelBaseInfo()
    this.findPageDataList()
    this.findPageDataList2()
  },
  methods: {
    getColonelBaseInfo() {
      getColonelBaseInfo({ userId: this.currentData.userId }).then(res => {
        if (res.success) {
          this.baseInfo = res.result
        }
      })
    },
    findPageDataList() {
      let params = { userId: this.currentData.userId, pageIndex: this.current, size: this.size }
      if (this.dateVal1) {
        params.startDate = this.getMonthRange(this.dateVal1).startDate
        params.endDate = this.getMonthRange(this.dateVal1).endDate
      }
      if (this.tabVal1 == 1) {
        getColonelSpread(params).then(res => {
          if (res.success) {
            this.dataList = res.result.list
            this.total = res.result.total
          }
        })
      } else if (this.tabVal1 == 2) {
        getStoreSpread(params).then(res => {
          if (res.success) {
            this.dataList = res.result.list
            this.total = res.result.total
          }
        })
      } else if (this.tabVal1 == 3) {
        getUserSpread(params).then(res => {
          if (res.success) {
            this.dataList = res.result.list
            this.total = res.result.total
          }
        })
      }
    },
    findPageDataList2() {
      let params = { userId: this.currentData.userId, pageIndex: this.current2, size: this.size }
      if (this.dateVal2) {
        params.startDate = this.getMonthRange(this.dateVal2).startDate
        params.endDate = this.getMonthRange(this.dateVal2).endDate
      }
      params.typeList = ['BROKERAGE_COLONEL', 'BROKERAGE_STORE', 'BROKERAGE_USER', 'USER_SHARE']
      getIntegralList(params).then(res => {
        if (res.success) {
          this.dataList2 = res.result.list
          this.total2 = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageChage2(page) {
      this.current2 = page
      this.findPageDataList2()
    },
    dateChange() {
      this.current = 1
      this.findPageDataList()
    },
    dateChange2() {
      this.current2 = 1
      this.findPageDataList2()
    }
  }
}
</script>

<style lang="scss" scoped>
.detial-box {
  .head-area {
    position: relative;
    padding: 20px;
    background: #fff;
    .avatar {
      width: 100px;
      height: 100px;
      margin-top: 10px;
      margin-right: 15px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header-tip {
      padding-top: 15px;
      width: 320px;
      vertical-align: middle;
      .header-tip-title {
        font-size: 14px;
        color: #000;
        margin-bottom: 12px;
      }
      .header-tip-desc-sp {
        color: #4e5969;
        font-size: 14px;
        line-height: 30px;
        .tip-label {
          width: 80px;
          margin-right: 5px;
          text-align: right;
        }
        .tip-value {
          width: 215px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .header-right-area {
      width: calc(100% - 500px);
      .header-right-item {
        padding: 20px 16px;
        width: calc(50% - 12px);
        margin-right: 12px;
        height: 128px;
        background: linear-gradient(180deg, #f2f9fe -3%, #e6f4fe 100%), #f7f8fa;
        border-radius: 3px;
        color: #1d2129;
        font-size: 14px;
        .tit-area {
          margin-bottom: 8px;
        }
        .num-area {
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: 500;
        }
        .zr-area {
          font-size: 12px;
          color: #4e5969;
          span {
            color: #1d2129;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      // .header-right-item:nth-child(2) {
      //   background: linear-gradient(180deg, #f5fef2 -3%, #e6feee 100%), #f7f8fa;
      // }
      .header-right-item:nth-child(2) {
        background: linear-gradient(180deg, #f6f7ff -3%, #ececff 100%), #f7f8fa;
      }
    }
    .edit-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .content-area {
    margin-top: 16px;
    .table-content {
      position: relative;
      padding: 10px 20px;
      margin-bottom: 16px;
      min-height: 200px;
      background: #fff;
      .date-change {
        position: absolute;
        right: 20px;
        top: 8px;
        z-index: 2;
      }
      .table-area {
        margin: 0 auto 10px;
      }
    }
  }
  .btns-area {
    margin-top: 20px;
    padding-left: 30px;
  }
  .tip-value.status2 {
    color: #00b42a;
  }
}
</style>