import { postRequest } from '@/libs/axios';


// 团长分销列表
export const getColonelBrokerageList = (params) => {
    return postRequest('/api/colonel/brokerage-page', params)
}
// 团长分销基本信息
export const getColonelBaseInfo = (params) => {
    return postRequest('/api/colonel/brokerage-base-info', params)
}
// 团长分销-团队推广
export const getColonelSpread = (params) => {
    return postRequest('/api/colonel/brokerage-colonel-spread', params)
}
// 团长分销-商家推广
export const getStoreSpread = (params) => {
    return postRequest('/api/colonel/brokerage-store-spread', params)
}
// 团长分销-用户推广
export const getUserSpread = (params) => {
    return postRequest('/api/colonel/brokerage-user-spread', params)
}
// 圈豆账户流水记录[分页]
export const getIntegralList = (params) => {
    return postRequest('/api/colonel/integral/list', params)
}

// 团长考核绩效季度列表
export const getTaskQuarterList = (params) => {
    return postRequest('/api/colonel/task/quarter/list', params)
}

// 团长考核绩效
export const getTaskList = (params) => {
    return postRequest('/api/colonel/task', params)
}

// 活动发布
export const getColonelGroups = (params) => {
    return postRequest('/api/colonel/groups', params)
}

